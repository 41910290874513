body{
    background:#FFFFFF;
    margin: 0;
    font-family: 'Merriweather Sans', sans-serif;
    font-size:17px;
    font-weight:300;
    color:#3D434B;

}

.clear{
    clear:both;
}

img {
    margin:0;
}

.img-responsive_full{
    display: block;
    width: 100%;
    height: auto;
}


b,strong{
    font-weight:700;
}

h1{
    color:#3D434B;
    font-size:35px;
    font-weight:700;
}

h2{
    color:#3D434B;
    font-size:30px;
    font-weight:700;
}

h3{
    color:#3D434B;
    font-size:25px;
    font-weight:400;
}


a {
    color:#333333;
    border:none;
    text-decoration:underline;
}

a:hover,
.active a{
    color:#333333;
    text-decoration:underline;
}

.row_margin{
    margin-right:0;
    margin-left:0;
}

.pad{
    padding-left:0;
    padding-right:0;
}

.center-block{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.banner_header{
    background: no-repeat center;
    background-size: cover;
    display:block;
    min-height:756px;
}

.navbar-nav>li{
    text-transform: uppercase;
}

.navbar-default{
    border:none;
    background-color:transparent;
}

.navbar-default .navbar-nav>li>a{
    color:rgba(255, 255, 255, 0.79);
    text-decoration:none;
    font-size:17px;
    padding: 13px;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a.active{
    color:#fff;
    text-decoration:none;
    font-size:17px;
}

.navbar-default .navbar_grey .navbar-nav>li>a{
    color:#9EA1A5;
}

.navbar-default .navbar_grey .navbar-nav>li>a:hover,
.navbar-default .navbar_grey .navbar-nav>li>a.active{
    color:#3D434B;
}

.menu_header{
    margin-top:28px;
}

.header_menu{
    border-bottom:1px solid rgba(255, 255, 255, 0.33);
    padding: 11px 0;
}

.header_menu_grey{
    border-bottom:1px solid #C4C6C9;
}

.testo_corsivo{
    font-size:80px;
    font-family: 'Dancing Script', cursive;
    text-align:center;
    color:#fff;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
}

.img_grazie_banner{
    text-align:center;
    color:#fff;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
}

.testo_grande{
    font-size:56px;
    text-align:center;
    color:#fff;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
    font-weight:700;
    margin-top: 20px;
}

.testo_sottotitolo{
    text-transform: uppercase;
    font-size:20px;
    color:#fff;
    text-align:center;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
    font-weight: 400;
    letter-spacing: 0.7px;
}

.button_contattaci{
    background-color:transparent;
    border:1px solid #fff;
    padding:15px 20px;
    color:#fff;
    text-decoration:none;
    font-weight:600;
    font-size:19px;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
    margin-top:40px;
}

.button_contattaci:hover,
.button_contattaci.active{
    background-color:rgba(255, 255, 255, 0.38);
    color:#fff;
    border:1px solid #fff;
}

.contenuti_banner{
    padding:80px 20px;
}

.lavori_home{
    background-color:#3D434B;
    margin:15px 0;
}

.spazio_testo_lavoro{
    color:#fff;
    padding:50px 20px 50px 120px;
}

.titolo_lavoro{
    font-size:30px;
    font-weight:700;
    margin-top:20px;
}

.sottotitolo_lavoro{
    font-size:20px;
    font-weight:700;
}

.testo_lavoro{
    font-size:16px;
}

.button_leggi{
    padding:10px 25px;
    font-size:16px;
    text-decoration:none;
    margin-top:20px;
}

.button_leggi:hover,
.button_leggi.active{
    text-decoration:none;
    color:#fff;
}

.spazio_testo_lavoro a{
    color:#fff;
    text-decoration:none;
}

.scorri{
    border-top:#666666 1px solid;
    font-size:13px;
    padding-top:15px;
    margin-top:20px;
}

.bg_servizi_home{
    background:url("../images/bg_servizi_home.jpg") no-repeat center;
    background-size: cover;
}

.bg_garanzia_home{
    background:url("../images/bg_garanzia.jpg") no-repeat center;
    background-size: cover;
}

.bg_portoni{
    background:url("../images/portoni.jpg") no-repeat center;
    background-size: cover;
    border-bottom:2px solid #fff;
    min-height:480px;
}

.bg_portinerie{
    background:url("../images/portinerie.jpg") no-repeat center;
    background-size: cover;
    border-bottom:2px solid #fff;
    min-height:480px;
}

.bg_chiese{
    background:url("../images/chiese.jpg") no-repeat center;
    background-size: cover;
    border-bottom:2px solid #fff;
    min-height:480px;
}

.bg_bussole{
    background:url("../images/bussole.jpg") no-repeat center;
    background-size: cover;
    border-bottom:2px solid #fff;
    min-height:480px;
}

.bg_ascensori{
    background:url("../images/ascensori.jpg") no-repeat center;
    background-size: cover;
    border-bottom:2px solid #fff;
    min-height:480px;
}

.bg_opere{
    background:url("../images/opere.jpg") no-repeat center;
    background-size: cover;
    border-bottom:2px solid #fff;
    min-height:480px;
}

.bg_cancellate{
    background:url("../images/cancellate.jpg") no-repeat center;
    background-size: cover;
    border-bottom:2px solid #fff;
    min-height:480px;
}

.bg_manutenzione{
    background:url("../images/manutenzione.jpg") no-repeat center;
    background-size: cover;
    border-bottom:2px solid #fff;
    min-height:480px;
}

.spazio_servizi h1{

}

.carousel-control.left{
    background-image:none;
}

.carousel-control.right{
    background-image:none;
}

.mappa_lavori_slider{
    height:100%;
}

.img_primadopo{
    height:100%;
}

.testo_bianco{
    color:#fff;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
}

.testo_bianco h1{
    color:#fff;
    font-size:54px;
}

.testo_bianco h3{
    color:#fff;
    font-size:54px;
    font-weight:bold;
}

.spazio_restauriamo{
    padding:30px;
}

.titolo_piccolo{
    font-size:12px;
}

.lista_servizi{
    float:left;
    padding-right:10px;
}

.item_lista_servizi{
    margin: 28px 0;
    display: block;
}

.titolo_noi{
    font-family: 'Merriweather', serif;
    font-size:25px;
    line-height:35px;
}

.spazio_testo_noi{
    padding-top:40px;
    padding-right:19%;
    padding-left: 4%;
}

.spazio_garanzia{
    padding:140px 30px;
}

.spazio_servizi{
    padding:80px 30px;
}

.pal_legenda{
    float:left;
    padding: 8px 10px;
}

.spazio_legenda{
    position:absolute;
    display:block;
    z-index:100;
    background:url("../images/bg_legenda.png") repeat;
    top:20%;
    left:10%;
    width:20%;
    padding:10px 10px 0 0;
}

.mappa_legenda{
    position:relative;
}

.footer_grigio{
    background-color:#3D434B;
    color:#fff;
    font-size:15px;
    padding:20px 0;
}

.social{
    float:left;
    padding-right:10px;
}

.scritta_social{
    margin-top:4px;
}

.spazio_social{
    padding:10px 0;
}

.titolo_footer{
    font-family: 'Merriweather', serif;
}

.footer{
    font-size:14px;
}

.footer ul {
    float:left;
    text-align:center;
    padding: 0;
}

.footer li {
    float:left;
    list-style-type:none;
    padding: 14px 40px 14px 0;
}

.footer a{
    text-decoration:none;
}

.titolo_pagina{
    font-size:30px;
    font-weight:bold;
}


.nome{
    font-weight:bold;
    padding-top:10px;
}

.riga_foto{
    padding:20px 0;
}

.img_gallery{
    margin-bottom:30px;
}

.contenuto_bianco{
    margin-top:20px;
}

.button_scopri{
    position:absolute;
    z-index:200;
    display:block;
    left:50%;
    margin-left:-56px;
    background-color: transparent;
    border: 1px solid #fff;
    padding: 15px 20px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    font-size: 19px;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
    top: 43%;
}

.button_scopri:hover,
.button_scopri.active{
    background-color:rgba(255, 255, 255, 0.38);
    color:#fff;
    border:1px solid #fff;
}

.immagine_link{
    position:relative;
    margin-bottom:10px;
}

.riga_link_lavori{
    padding-bottom:20px;
}

.pagination>li>a, .pagination>li>span{
    color:#333;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{
    background-color: #333;
    border-color: #333;
}

.contenuto_lavoro_primadopo{
    background-color:#3D434B;
}

.contenuto_lavoro_primadopo_interna{
    margin-bottom:20px;
}

.spazio_testo_lavoro_int{
    padding:25px;
    width:55%;
}

.imagine_primadopo{
    width:45%;
}

.pulsante_indietro{
    margin-bottom:30px;
}

.button_indietro{
    text-decoration:none;
    font-weight:bold;
}

.panel-default>.panel-heading{
    background-color:#3D434B;
    color:#fff;
}

.panel-title>.small, .panel-title>.small>a, .panel-title>a, .panel-title>small, .panel-title>small>a:hover,
.panel-title>.small, .panel-title>.small>a, .panel-title>a, .panel-title>small, .panel-title>small>a.active{
    color:#fff;
    text-decoration:none;
}

.panel-heading{
    padding:15px;
}

.ico_piu{
    float:right;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body{
    background-color:#F2F2F2;
}

.spazio_form{
    margin:20px 0;
}

.button_form{
    background-color:#000000;
    color:#fff;
    padding:15px 30px;
    text-decoration:none;
    text-transform: uppercase;
}

.button_form:hover,
.button_form.active{
    text-decoration:none;
    background-color:#3D434B;
    color:#fff;
}

.form_sx{
    padding-left:0;
}

.form_dx{
    padding-right:0;
}

label{
    font-size:14px
}


/* SOLO TABLET */
@media (max-width: 1280px){

.spazio_testo_noi{
    padding:20px;
}



}

/* SOLO TABLET */
@media (max-width: 1200px){

.spazio_logo{
    width:30%;
}

.spazio_menu{
   width:70%;
}

.logo_riva{
    margin-top:20px;
}

.button_contattaci{
    font-size:16px;
}

.greca_lavoro{
    display:none;
}

.spazio_testo_lavoro{
    padding: 8px 20px;
}

.titolo_noi{
    font-size:20px;
    line-height:25px;
}




}


/* SOLO TABLET */
@media (max-width: 1021px){

.spazio_logo{
    width:auto;
}

.spazio_menu{
   width:auto;
}

.logo_riva{
    margin-top:auto;
}

.navbar-nav>li{
    display: inline-block;
}

.navbar-nav{
    text-align: center;
}

.nav{
    padding-left:inherit;
}

.header_menu{
    border-bottom:1px solid rgba(255, 255, 255, 0.33);
    padding: 0;
}

.spazio_testo_lavoro_int{
    padding:25px;
    width:auto;
}

.imagine_primadopo{
    width:auto;
}


}

/* SOLO MOBILE */
@media (max-width: 991px) {

.spazio_testo_lavoro{
    padding-left:120px;
}

.spazio_legenda{
    position:initial;
    background:none;
    padding:10px 10px 0 0;
    width:100%;
    left:20px;
}


}


/* SOLO MOBILE */
@media (max-width: 767px) {

.navbar-nav>li{
    display: inherit;
}

.navbar-nav{
    text-align: left;
}

.nav{
    padding-left:0;
}

.navbar-header{
    background-color: #fff;
}

.navbar-default .navbar-brand{
    text-decoration: none;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form{
    background-color: #fff;
}

.banner_header{
    min-height:214px;
}

.navbar-default .navbar-nav>li>a{
    color:rgb(82, 82, 82);
    text-decoration:none;
    font-size:17px;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a.active{
    color:#000;
    text-decoration:none;
    font-size:17px;
    font-weight:700;
}

.header_menu{
    border-bottom:0;
    padding: 0;
}

.testo_corsivo{
    font-size:50px;
    font-family: 'Dancing Script', cursive;
    text-align:center;
    color:#fff;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
}

.testo_grande{
    font-size:30px;
    text-align:center;
    color:#fff;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
    font-weight:700;
    margin-top: 20px;
}

.testo_sottotitolo{
    text-transform: uppercase;
    font-size:18px;
    color:#fff;
    text-align:center;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
    font-weight: 400;
    letter-spacing: 0.7px;
}

.button_contattaci{
    background-color:transparent;
    border:1px solid #fff;
    padding:15px 20px;
    color:#fff;
    text-decoration:none;
    font-weight:600;
    font-size:19px;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
    margin-top:40px;
}

.button_contattaci:hover,
.button_contattaci.active{
    background-color:rgba(255, 255, 255, 0.38);
    color:#fff;
    border:1px solid #fff;
}

.contenuti_banner{
    padding:20px 10px;
}

.spazio_testo_lavoro{
    padding-left:25px;
    padding-bottom:25px;
}

.button_leggi{
    margin-top:10px;
}

.item_lista_servizi{
    margin: 15px 0;
}

.spazio_restauriamo h1{
    font-size:35px;
}

.spazio_restauriamo p{
    font-size:15px;
}

.spazio_garanzia{
    padding:50px 30px;
}

.info_footer{
    padding-top:20px;
}

.spazio_servizi h3{
    font-size:30px;
}


.form_sx{
    padding-left:15px;
}

.form_dx{
    padding-right:15px;
}

#mappa-ultimi-lavori {
    height: 300px !important;
}


}


#mappa-ultimi-lavori>div {
    top:0;
}





/** servizi **/
.bg_servizi{
    background-position:no-repeat center;
    background-size: cover;
    border-bottom:2px solid #fff;
    min-height:480px;
}


/*****/

/*
#map-canvas {width:100%;display:block;}
	#map-canvas:before {content:"";display:block;padding:99% 0 0 0;}
*/

.info_footer a {color:#fff;}

.carousel-control {width:8%;}
.twentytwenty-container {text-align:center;}
.twentytwenty-container img {width:100%;}

.twentytwenty-after-label, .twentytwenty-before-label {display:none;}

.pos-relative {position:relative;}
#map-canvas {position:absolute;top:0;bottom:0;left:0;right:0;}
/*#map-canvas.mappa_lavoro_home {bottom:20px;}*/


#mappa-ultimi-lavori {width:100%;display:block;position:relative;}
#mappa-ultimi-lavori:before {content:"";display:block;padding:20% 0 0 0;} /* 75% = 4/3 , 56.25% = 16:9, 200% = 1:2 */

.downloads {margin-top:22px;}
.downloads .display-block {display:block}
    .downloads .display-block button {width:100%;text-align:left;margin-bottom:15px;background-color:#eee;}
    .downloads .display-block button span {float:right;}
